import React, { useEffect, useState, useRef } from "react";
import AdvancedSearchPopup from "./AdvancedSearchPopup";
import { track } from "@vercel/analytics";

const InputSection = ({
  query,
  setQuery,
  handleSubmit,
  isLoading,
  textareaRef,
  startYearInput,
  setStartYearInput,
  endYearInput,
  setEndYearInput,
  selectedJournals,
  setSelectedJournals,
  isAuto,
  setIsAuto,
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const [isAdvancedSearchOpen, setIsAdvancedSearchOpen] = useState(false);
  const [isPrimaryDataOnly, setIsPrimaryDataOnly] = useState(false);

  // Remove advanced search logic from InputSection
  const toggleAdvancedSearch = () => {
    setIsAdvancedSearchOpen(!isAdvancedSearchOpen);
  };

  const handleClickOutside = (event) => {
    if (
      !event.target.closest(".advanced-search-popup") &&
      !event.target.closest(".advanced-search-button")
    ) {
      setIsAdvancedSearchOpen(false);
    }
  };

  useEffect(() => {
    if (isAdvancedSearchOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isAdvancedSearchOpen]);

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && !event.shiftKey && query.trim() !== "") {
      event.preventDefault();
      handleSubmit(event);
    }
  };

  const handleInputChange = (e) => {
    setQuery(e.target.value);
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto";
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  };

  // Remove selected journal
  const removeJournal = (journalToRemove) => {
    setSelectedJournals(
      selectedJournals.filter((journal) => journal !== journalToRemove)
    );
  };

  // Remove start year
  const removeStartYear = () => {
    setStartYearInput("");
  };

  // Remove end year
  const removeEndYear = () => {
    setEndYearInput("");
  };

  const tags = [
    startYearInput && {
      name: "Start",
      value: startYearInput,
      backgroundColor: "bg-orange-100",
      textColor: "text-orange-800",
      darkBackgroundColor: "dark:bg-orange-900",
      darkTextColor: "dark:text-orange-300",
    },
    endYearInput && {
      name: "End",
      value: endYearInput,
      backgroundColor: "bg-green-100",
      textColor: "text-green-800",
      darkBackgroundColor: "dark:bg-green-900",
      darkTextColor: "dark:text-green-300",
    },
    isPrimaryDataOnly && {
      name: "Primary",
      value: "",
      backgroundColor: "bg-purple-100",
      textColor: "text-purple-800",
      darkBackgroundColor: "dark:bg-purple-900",
      darkTextColor: "dark:text-purple-300",
    },
    ...selectedJournals.map((journal) => ({
      name: "Journal",
      value: journal,
      backgroundColor: "bg-blue-100",
      textColor: "text-blue-800",
      darkBackgroundColor: "dark:bg-blue-900",
      darkTextColor: "dark:text-blue-300",
    })),
  ].filter(Boolean);

  return (
    <form onSubmit={handleSubmit} className="relative">
      {/* Tags */}
      {tags.length > 0 && (
        <div className="flex flex-wrap mb-2 space-x-1">
          {tags.map((tag, index) => (
            <span
              key={index}
              className={`flex items-center text-xs font-medium mr-2 px-2.5 py-0.5 rounded ${tag.backgroundColor} ${tag.textColor} ${tag.darkBackgroundColor} ${tag.darkTextColor}`}
            >
              {`${tag.name}: ${tag.value}`}
              <button
                type="button"
                onClick={() => {
                  if (tag.name === "Journal") removeJournal(tag.value);
                  else if (tag.name === "Start") removeStartYear();
                  else if (tag.name === "End") removeEndYear();
                }}
                className="ml-1 text-xs text-gray-500 hover:text-gray-700"
              >
                ×
              </button>
            </span>
          ))}
        </div>
      )}

      <div
        className={`flex items-center bg-gray-700 rounded-lg p-2 transition-all duration-200 ease-in-out ${
          isFocused ? "scale-105 shadow-lg" : ""
        }`}
        style={{ borderRadius: "0.75rem" }}
        onClick={() => textareaRef.current?.focus()}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
      >
        {/* Plus Button */}
        <button
          type="button"
          onClick={toggleAdvancedSearch}
          className="advanced-search-button text-gray-400 p-2 rounded-full hover:text-[#ED782F] transition-colors duration-200 ease-in-out"
        >
          {/* Plus SVG */}
          <svg
            viewBox="0 0 24 24"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            className="w-4 h-4"
          >
            <g>
              <rect width="24" height="24" fill="transparent"></rect>
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13 9C13 8.44772 12.5523 8 12 8C11.4477 8 11 8.44772 11 9V11H9C8.44772 11 8 11.4477 8 12C8 12.5523 8.44772 13 9 13H11V15C11 15.5523 11.4477 16 12 16C12.5523 16 13 15.5523 13 15V13H15C15.5523 13 16 12.5523 16 12C16 11.4477 15.5523 11 15 11H13V9ZM2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12Z"
                fill="currentColor"
              />
            </g>
          </svg>
        </button>

        {/* Textarea */}
        <textarea
          id="chat"
          disabled={isLoading}
          ref={textareaRef}
          className="flex-1 bg-transparent text-sm text-white placeholder-gray-400 focus:outline-none resize-none"
          placeholder="Ask Cure..."
          value={query}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          rows={1}
          style={{
            overflow: "hidden",
          }}
        />

        {/* Submit Button or Loading Spinner */}
        {isLoading ? (
          <div role="status" className="flex items-center">
            {/* Loading Spinner SVG */}
            <svg
              aria-hidden="true"
              className="w-6 h-6 text-gray-200 animate-spin fill-orange-600"
              viewBox="0 0 100 101"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M100 50.5908C100 78.2051 77.6142
                100.591 50 100.591C22.3858 100.591 0
                78.2051 0 50.5908C0 22.9766 22.3858
                0.59082 50 0.59082C77.6142 0.59082
                100 22.9766 100 50.5908ZM9.08144
                50.5908C9.08144 73.1895 27.4013
                91.5094 50 91.5094C72.5987 91.5094
                90.9186 73.1895 90.9186
                50.5908C90.9186 27.9921 72.5987
                9.67226 50 9.67226C27.4013 9.67226
                9.08144 27.9921 9.08144 50.5908Z"
                fill="currentColor"
              />
              <path
                d="M93.9676 39.0409C96.393
                38.4038 97.8624 35.9116 97.0079
                33.5539C95.2932 28.8227 92.871
                24.3692 89.8167 20.348C85.8452
                15.1192 80.8826 10.7238 75.2124
                7.41289C69.5422 4.10194 63.2754
                1.94025 56.7698 1.05124C51.7666
                0.367541 46.6976 0.446843 41.7345
                1.27873C39.2613 1.69328 37.813
                4.19778 38.4501 6.62326C39.0873
                9.04874 41.5694 10.4717 44.0505
                10.1071C47.8511 9.54855 51.7191
                9.52689 55.5402 10.0491C60.8642
                10.7766 65.9928 12.5457 70.6331
                15.2552C75.2735 17.9648 79.3347
                21.5619 82.5849 25.841C84.9175
                28.9121 86.7997 32.2913 88.1811
                35.8758C89.083 38.2158 91.5421
                39.6781 93.9676 39.0409Z"
                fill="currentFill"
              />
            </svg>
            <span className="sr-only">Loading...</span>
          </div>
        ) : (
          <button
            type="submit"
            disabled={query.trim() === ""}
            onClick={track("Submitted query", { query: query })}
            className={`p-2 rounded-full hover:bg-gray-600 transition-colors ${
              query.trim() === ""
                ? "opacity-50 cursor-not-allowed"
                : "text-[#ED782F]"
            }`}
          >
            <svg
              className="w-5 h-5 rotate-90"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 18 20"
            >
              <path d="M17.914 18.594l-8-18a1 1 0 00-1.828 0l-8 18a 1 1 0 001.157 1.376L8 18.281V9a 1 1 0 012 0v9.281l6.757 1.689a1 1 0 001.157-1.376z" />
            </svg>
            <span className="sr-only">Send message</span>
          </button>
        )}
      </div>

      {/* Use AdvancedSearchPopup component */}
      <AdvancedSearchPopup
        isOpen={isAdvancedSearchOpen}
        togglePopup={toggleAdvancedSearch}
        startYearInput={startYearInput}
        setStartYearInput={setStartYearInput}
        endYearInput={endYearInput}
        setEndYearInput={setEndYearInput}
        selectedJournals={selectedJournals}
        setSelectedJournals={setSelectedJournals}
        isAuto={isAuto}
        setIsAuto={setIsAuto}
      />
    </form>
  );
};

export default InputSection;
